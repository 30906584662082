
<template>
  <div>
    <el-drawer class="show-drawer-box"
               title="请选择科目"
               :visible.sync="dialogDrawer"
               :direction="'btt'">
      <div class="show-drawer-item" v-if="dataInfo && dataInfo.subjectList.length > 0">
        <el-tabs v-model="activeDrawerName">
          <el-tab-pane :label="item.name" :name="item.id" v-for="item in dataInfo.subjectList">
            <div class="d-item" v-for="val in item.children" @click="handleClick(val.name)">
              <span>{{val.name}}</span>
              <i class="el-icon-circle-plus-outline"></i>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-drawer>
  </div>
</template>
<script>
  export default {
    data(){
      return {
        dialogDrawer: false,
        activeDrawerName: '1',
        dataInfo: ''
      }
    },
    created() {},
    methods: {
      showDrawer(param){
        // console.log(param)
        this.dialogDrawer = param.show
        this.dataInfo = param.info
      },
      handleClick(name) {
        // console.log(name)
        this.$emit("subject", name)
        this.dialogDrawer = false
      }
    }
  }
</script>
<style scoped lang="less">
.show-drawer-box{
  /deep/ .el-drawer__header{
    margin-bottom: 10px !important;
  }
  /deep/ .el-drawer{
    height: 50% !important;
    border-radius: 15px 15px 0 0;
  }
  .show-drawer-item{
    position: relative;
    width: 94%;
    margin: 0 auto;
    height: calc(100% - 60px);
    /deep/ .el-tabs__header{
      margin-bottom: 10px !important;
      .el-tabs__nav-next, .el-tabs__nav-prev{
        width: 20px !important;
      }
      .el-tabs__item{
        padding: 0 12px !important;
      }
    }
    /deep/ .el-tabs__content{
      overflow: auto;
      height: 200px;
    }
    .d-item{
      width: 90%;
      height: 40px;
      line-height: 40px;
      padding: 0 20px;
      margin: 0 auto 10px auto;
      border-radius: 5px;
      border: 1px solid #DCDFE6;
      background-color: #ffffff;
      color: #606266;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }
}
</style>
